<template>
  <div class="diaryManage">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <!-- <el-row class="header">
        <el-form ref="form" :model="logForm" inline>

          <el-form-item label="日志类型">
            <el-select v-model="logForm.operatorName" placeholder="请选择日志类型" filterable clearable>
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in logType"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-row>-->
      <!-- 表格区域 -->
      <el-row>
        <el-table
          :data="tableList"
          style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }"
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="operatorName" label="操作账户" sortable align="center"></el-table-column>
          <el-table-column prop="content" label="日志内容" align="center"></el-table-column>
          <el-table-column prop="clsname" label="操作类库" align="center"></el-table-column>
          <!-- <el-table-column prop="logType" label="日志类型" sortable align="center"> -->
          <!-- <template slot-scope="scope">
              <el-tag
                effect="dark"
                :type="scope.row.logType === '新增'? '': 'success'"
              >{{ scope.row.logType }}</el-tag>
          </template>-->
          <!-- </el-table-column> -->
          <el-table-column prop="loginIp" label="IP地址" sortable align="center"></el-table-column>
          <el-table-column prop="createTime" label="操作时间" sortable align="center"></el-table-column>
        </el-table>
      </el-row>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'diaryManage',
  components: {

  },
  data() {
    return {
      logForm: {
        operatorName: ''
      }, // 搜索框表单
      logType: [
        {
          value: '登录',
          label: '登录'
        },
        {
          value: '新增',
          label: '新增'
        },
        {
          value: '修改',
          label: '修改'
        },
        {
          value: '删除',
          label: '删除'
        }
      ], // 日志类型列表
      tableList: []// 日志列表
    }
  },
  created() {
    this.getAllLogType()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass({ row, rowIndex }) {
      return 'background:#ccc'
    },
    // 获取所有日志
    async getAllLogType() {
      const { data: res } = await this.$http.post('/userManageServer/log/selectLogList', {})
      // console.log(data)
      if (res.statusCode !== '200') return this.$message.error('获取客户列表失败')
      this.tableList = res.data
    },
    // 点击搜索按钮触发
    search() {
      // 判断是否选中
      if (this.logForm.operatorName === '') return this.$message.error('请选择日志类型')
      // 调接口，进行查询
      // const { data } = await this.$http.get('', this.logForm.logType)
      // console.log(data)
      // 判断请求是否成功
      // if (data.sattus !== '200') return this.$message.error('查询失败')
      // this.tableList = data
    }
  }
}
</script>

<style lang="less" scoped>
</style>
